<template>
    <div class="nav-header">
        <i class="el-icon-arrow-left" @click="back"></i>
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
			url: {
				type: String,
				default: ''
			}
        },
        data() {
            return {
                
            }
        },
        methods: {
            back() {
                if(this.url) {
                	this.$router.push(this.url)
                } else {
                	this.$router.go(-1)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .nav-header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 40;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 4.8rem;
        box-sizing: border-box;
        padding: 0 1.2rem;
        background-color: #FFFFFF;
        
        .el-icon-arrow-left {
            position: absolute;
            top: 50%;
            left: 1.2rem;
            transform: translateY(-50%);
            font-size: 2rem;
			font-weight: bold;
            color: #000000;
            white-space: nowrap;
        }
        
        .title {
            max-width: 70%;
            font-size: 1.7rem;
            font-weight: bold;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>